import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Button, Container } from 'reactstrap';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import Jumbotron from '../../../components/Jumbotron';
import { SECONDARY } from '../../../utils/colors';
import Thumb from '../../../assets/svgs/jobs.svg';

const Analista = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO title="Analista de Requisitos Sênior na Central do Frete" />
    <Jumbotron
      Image={Thumb}
      background={SECONDARY}
      title="Analista de Requisitos Sênior"
      subtitle="Venha ajudar a descomplicar a forma como as empresas contratam fretes"
    />
    <Container className="pb-5 pt-4">
      <h4>Prazer! Somos a Central do Frete</h4>
      <p>
        Nós somos uma startup de tecnologia que está transformando a maneira
        como as empresas contratam fretes.
      </p>
      <p>
        Em um mundo onde descomplicar é a palavra de ordem, chegamos para criar
        uma experiência mais satisfatória tanto para quem compra quanto para
        quem vende.
      </p>
      <h4>Local</h4>
      <p>
        Remoto: Todo o time de desenvolvimento trabalha remoto (Apenas a equipe
        de operação fica em São Caetano do Sul/SP)
      </p>
      <h4>Modalidade de contrato</h4>
      <p>Contrato PJ de prestação de serviços.</p>
      <h4>Atividades a desenvolver</h4>
      <ul>
        <li>
          Realizar o levantamento das necessidades dos usuários, mapeando as
          regras de negócio e processos envolvidos;
        </li>
        <li>
          Elaborar documento de especificação técnica de projetos de software;
        </li>
        <li>Escrever e acompanhar as tasks no board (Kanban e Scrum);</li>
        <li>Participar da definição da arquitetura de soluções;</li>
        <li>Participar da geração da especificação técnica;</li>
        <li>Participar do processo de implantação das soluções;</li>
        <li>
          Interagir com os fornecedores e efetuar acompanhamento das demandas
          solicitadas.
        </li>
      </ul>
      <h4>Competências Imprescindíveis:</h4>
      <ul>
        <li>Análise, levantamento e especificação de requisitos;</li>
        <li>Modelagem UML;</li>
        <li>Experiência com BPMN (Bizagi);</li>
        <li>
          Técnicas ágeis de requisitos de software: user story, product backlog,
          priorização de requisitos por valor de negócio;
        </li>
        <li>Uso de ferramentas de modelagem (CASE);</li>
        <li>Especificação e validação de casos de teste;</li>
        <li>Conhecimento de técnicas de reunião e entrevista.</li>
      </ul>
      <h4>O que brilharia aos nossos olhos</h4>
      <ul>
        <li>Que trabalhe com autonomia para priorizar tarefas;</li>
        <li>
          Com capacidade de transformar problemas complexos em experiências
          simples e de impacto;
        </li>
        <li>Noções de mercado de logística;</li>
        <li>Ter disciplina;</li>
        <li>
          Ser analítico (atento a detalhes, organizado, focado, assertivo);
        </li>
        <li>Ser uma pessoa que está aberta a novas tecnologias;</li>
        <li>Ser adaptável, com facilidade de mudanças;</li>
        <li>
          Ter atitude de dono do negócio, ser despojado e ter jogo de cintura;
        </li>
        <li>Gostar de resolver problemas e ser colaborativo;</li>
        <li>Ser sociável e focado na experiência do cliente.</li>
        <li>Senso de responsabilidade e urgência;</li>
        <li>Experiência trabalhando com metodologias ágeis.</li>
      </ul>
      <h4>Sobre o processo seletivo</h4>
      <ol>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Comece a jornada:
          </h5>
          Para iniciar o seu processo, você faz sua inscrição exclusivamente
          através do botão increve-se logo abaixo.
        </li>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Mostre o que você sabe:
          </h5>
          Quase lá! Nesta etapa, você será desafiado a criar uma pequena
          interface para validar suas aptidões e competências. Se aprovado no
          desafio, você é selecionado para etapa final.
        </li>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Vamos nos conhecer melhor:
          </h5>
          Ufa! Mais um pouquinho… Nesta fase, os responsáveis da área que você
          irá trabalhar fazem uma entrevista com você remotamente, via whereby
          (antigo appearin). Se acreditarmos que você tem alinhamento com o
          time, você é contratado.
        </li>
      </ol>
      <footer className="pb-5">
        <Button
          size="lg"
          target="_blank"
          href="https://forms.gle/JDcS3ZdTQD5Kx5eY6"
        >
          Inscreva-se
        </Button>
        <Link className="ml-3" to="/vagas">
          Ver todas as vagas
        </Link>
      </footer>
    </Container>
  </Layout>
);

Analista.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Analista;
